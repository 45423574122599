<template>
  <div class="box">
    <div class="all-problem">
      <template v-for="(item, index) in list">
        <div class="item">
          <div class="title">
            <span>{{ (index + 1) < 10 ? '0' + (index + 1) : index + 1 }}</span>
            <span class="name">{{ item.title }}</span>
          </div>
          <p class="content">{{ item.content }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const api = require('../../utils/api').api
export default {
  name: 'index',
  data () {
    return {
      list: []
    }
  },
  mounted () {
    this.$axios.get(api.problems, {
      params: {
        clientType: 2,
        plateName: '常见问题',
        page: 1,
        pageSize: 100
      }
    }).then(res => {
      this.list = res.data.data.data
    }).catch(err => {
    })
  }
}
</script>

<style scoped>
.box {
  width: 345px;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 20px;
}

.all-problem {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
  /*padding-bottom: calc(30rpx + constant(safe-area-inset-bottom)); !* 兼容 iOS < 11.2 *!*/
  /*padding-bottom: calc(30rpx + env(safe-area-inset-bottom)); !* 兼容 iOS >= 11.2 *!*/
}

.all-problem .item {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}

.all-problem .item:first-child {
  margin-top: 15px;
}

.all-problem .item .title {
  display: flex;
  font-size: 15px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  justify-content: center;
}

.all-problem .item .title .name {
  margin-left: 5px;
  display: inline-block;
  width: 325px;
}

.all-problem .item .content {
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 19px;
  font-size: 15px;
  font-weight: 400;
  margin-top: 8px;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
}

</style>
